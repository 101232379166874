var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',[_c('a-col',{staticStyle:{"padding-top":"8px"},attrs:{"span":24}},[_c('a-button',{staticClass:"btn-reset uppercase",on:{"click":_vm.addRow}},[_c('a-icon',{attrs:{"type":"plus-circle"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16,"type":"flex"}},[_c('a-col',{attrs:{"span":24}},[_c('div',{staticClass:"wrapper1"},[_c('div',{staticClass:"div1"})]),_c('a-form-model',{ref:"validateFormExpand",attrs:{"model":_vm.validateFormExpand}},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"rowKey":(item, index) => index,"pagination":false,"loading":_vm.loading,"components":_vm.components,"locale":{ emptyText: 'Chưa có dữ liệu' },"scroll":{ x: 'max-content' }},scopedSlots:_vm._u([{key:"value",fn:function(text, record, index){return [(record.editable === true)?_c('a-form-model-item',{ref:"value",attrs:{"prop":'items.' + index + '.value',"rules":[
                {
                  required: true,
                  message: 'Giá trị là bắt buộc',
                  trigger: 'change'
                },
                {
                  validator: _vm.inputNumberSpecialCharacters
                },
                {
                  max: 10,
                  message: 'Không nhập quá 10 ký tự'
                }
              ]}},[_c('a-input',{model:{value:(record.value),callback:function ($$v) {_vm.$set(record, "value", $$v)},expression:"record.value"}})],1):[_vm._v(" "+_vm._s(record.value)+" ")]]}},{key:"name",fn:function(text, record, index){return [(record.editable === true)?_c('a-form-model-item',{attrs:{"prop":'items.' + index + '.name',"rules":[
                {
                  required: true,
                  message: 'Tên là bắt buộc',
                  trigger: 'change'
                },
                {
                  max: 500,
                  message: 'Tên chỉ cho phép nhập tối đa 500 ký tự',
                  trigger: 'blur'
                }
              ]}},[_c('a-input',{model:{value:(record.name),callback:function ($$v) {_vm.$set(record, "name", $$v)},expression:"record.name"}})],1):[_vm._v(" "+_vm._s(record.name)+" ")]]}},{key:"description",fn:function(text, record, index){return [(record.editable === true)?_c('a-form-model-item',{ref:"description",attrs:{"prop":'items.' + index + '.description',"rules":{
                required: false,
                message: 'Trường bắt buộc',
                trigger: 'change'
              }}},[_c('a-input',{model:{value:(record.description),callback:function ($$v) {_vm.$set(record, "description", $$v)},expression:"record.description"}})],1):[_vm._v(" "+_vm._s(record.description)+" ")]]}},{key:"staDate",fn:function(text, record){return [(record.editable === true)?_c('a-form-model-item',[_c('a-date-picker',{staticStyle:{"margin":"-5px 0"},attrs:{"placeholder":"DD/MM/YYYY","format":_vm.dateDisplayFormat,"disabled-date":_vm.disabledStartDate},on:{"change":function($event){return _vm.onChangeDatePicker(record)}},model:{value:(record.staDate),callback:function ($$v) {_vm.$set(record, "staDate", $$v)},expression:"record.staDate"}})],1):[_vm._v(" "+_vm._s(_vm.moment.isMoment(record.staDate) ? record.staDate.format(_vm.datePlaceholderFormat) : record.staDate)+" ")]]}},{key:"endDate",fn:function(text, record){return [(record.editable === true)?_c('a-form-model-item',[_c('a-date-picker',{staticStyle:{"margin":"-5px 0"},attrs:{"placeholder":"DD/MM/YYYY","format":_vm.dateDisplayFormat,"disabled-date":_vm.disabledEndDate},on:{"change":function($event){return _vm.onChangeEndDate(record)}},model:{value:(record.endDate),callback:function ($$v) {_vm.$set(record, "endDate", $$v)},expression:"record.endDate"}})],1):[_vm._v(" "+_vm._s(_vm.moment.isMoment(record.endDate) ? record.endDate.format(_vm.datePlaceholderFormat) : record.endDate)+" ")]]}},{key:"displayOrder",fn:function(text, record, index){return [(record.editable === true)?_c('a-form-model-item',{ref:"displayOrder",attrs:{"prop":'items.' + index + '.displayOrder',"rules":[
                {
                  validator: _vm.numberRequiredMaxLength
                }
              ]}},[(record.editable === true)?_c('a-input',{model:{value:(record.displayOrder),callback:function ($$v) {_vm.$set(record, "displayOrder", $$v)},expression:"record.displayOrder"}}):_vm._e()],1):[_vm._v(" "+_vm._s(record.displayOrder)+" ")]]}},{key:"status",fn:function(text, record, index){return [(record.editable === true)?_c('a-form-model-item',{attrs:{"prop":'items.' + index + '.status',"rules":{
                required: true,
                message: 'Trạng thái là bắt buộc',
                trigger: 'change'
              }}},[_c('a-select',{model:{value:(record.status),callback:function ($$v) {_vm.$set(record, "status", $$v)},expression:"record.status"}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v("Không hoạt động")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("Hoạt động")])],1)],1):[_vm._v(" "+_vm._s(record.status === '1' ? 'Hoạt động' : 'Không hoạt động')+" ")]]}},{key:"operation",fn:function(text, record, index){return [(record.editable)?_c('div',[_c('span',{staticStyle:{"padding-right":"12px","cursor":"pointer"},on:{"click":function($event){return _vm.saveRow(record)}}},[_c('a-icon',{style:({color: '#F98500',fontSize: '14px'}),attrs:{"type":"save"}})],1),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onDeleteRow(record.globalListValueId, index)}}},[_c('a-icon',{style:({color: '#ee0033', fontSize: '14px'}),attrs:{"type":"delete"}})],1),_c('span',{staticStyle:{"cursor":"pointer","padding-left":"12px"},on:{"click":function($event){return _vm.cancelRow(index)}}},[_c('a-icon',{style:({color: '#ee0033', fontSize: '14px'}),attrs:{"type":"stop"}})],1)]):_c('div',[_c('span',{staticStyle:{"padding-right":"12px","cursor":"pointer"},on:{"click":function($event){return _vm.showEditRow(record.globalListValueId, record)}}},[_c('a-icon',{style:({color: '#ee0033',fontSize: '14px'}),attrs:{"type":"form"}})],1),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onDeleteRow(record.globalListValueId, index)}}},[_c('a-icon',{style:({color: '#ee0033', fontSize: '14px'}),attrs:{"type":"delete"}})],1)])]}}])},[_c('template',{slot:"actionTitle"},[_c('a-icon',{style:({fontSize: '14px'}),attrs:{"type":"control"}})],1)],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }